/* General Reset */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: white; /* Ensure the main background is white */
}

/* General App Styling */
.App {
  display: flex;
  font-family: 'Oswald', sans-serif; /* Apply Oswald font */
}

/* Sidebar Styling */
.sidebar {
  width: 200px; /* Fixed width for larger screens */
  background-color: #1a3d5d; /* Darker blue color for better contrast */
  padding: 20px;
  color: white;
  position: fixed; /* Fix the sidebar position */
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text horizontally */
  z-index: 2; /* Ensure the sidebar is above the content */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li a {
  color: white; /* Ensure the text color is white */
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
  text-transform: uppercase; /* Make text all caps */
  font-weight: bold; /* Make text bold */
}

.sidebar ul li a:hover {
  color: #add8e6;
}

/* Profile Image Styling */
.profile-container {
  text-align: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  cursor: pointer; /* Make the image look clickable */
}

/* Content Styling */
.content {
  margin-left: 220px; /* Adjust for the fixed sidebar width */
  padding: 20px;
  width: calc(100% - 220px);
  position: relative; /* Ensure content is positioned correctly */
  top: 0; /* Align content to the top */
}

/* Section Styling */
.section {
  min-height: 100vh; /* Ensure each section takes up at least the full height of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align content to the left */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 50px;
  /* Remove the box-shadow */
  box-shadow: none;
}

.section h2 {
  font-size: 2.5em; /* Largest font size for section title */
  margin-top: 0;
  color: #343a40;
}

.section p {
  color: #6c757d;
}

/* Home Section Styling */
.home-content {
  text-align: left; /* Align text to the left */
  max-width: 50%; /* Limit the width to 50% for better line breaks */
}

.home-content h1 {
  font-size: 4em; /* Increase font size */
  margin: 0;
}

.home-content .name {
  color: #343a40;
}

.home-content .surname {
  color: #343a40; /* Matching color */
}

.home-content .contact-info {
  font-size: 1.2em;
  color: #6c757d;
  margin: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.home-content .email {
  color: #add8e6;
}

.home-content .email a {
  color: #add8e6;
  text-decoration: none;
  font-size: 1em; /* Changed to match the parent font size */
  font-weight: bold;
  text-transform: uppercase;
}

.home-content .description {
  font-size: 1.1em;
  color: #6c757d;
  margin: 20px 0;
}

.home-content .social-icons {
  margin-top: 20px;
}

.home-content .social-icons a {
  color: #343a40;
  font-size: 1.5em;
  margin: 0 10px;
  transition: color 0.3s;
}

.home-content .social-icons a:hover {
  color: #add8e6;
}

/* Footer Styling */
.footer {
  text-align: center;
  padding: 20px;
  background-color: #343a40;
  color: white;
  width: 100%;
}

/* Experience Styling */
.experience-item {
  margin-bottom: 20px;
  padding: 10px;
}

.experience-item h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.experience-item .company,
.experience-item .duration,
.experience-item .location,
.experience-item .skills,
.experience-item .description,
.experience-item .projects {
  margin: 5px 0;
  font-size: 0.9em;
  color: #666;
}

.experience-item .skills {
  font-style: italic;
}

.experience-item .projects {
  font-weight: bold;
}

/* Skills Styling */
.skills-item {
  margin-bottom: 20px; /* Adjust the bottom margin */
  padding: 10px;
}

.skills-item h3 {
  margin: 0 0 10px 0; /* Add some space below the heading */
  font-size: 1.2em;
  color: #333;
}

.skills-item ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  columns: 2; /* Display skills in two columns */
  column-gap: 20px; /* Add some space between columns */
}

.skills-item ul li {
  margin: 5px 0; /* Add some space between list items */
  font-size: 0.9em;
  color: #666;
  display: flex;
  align-items: center;
}

.skill-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Honors & Awards Styling */
.honor-item {
  margin-bottom: 20px; /* Adjust the bottom margin */
  padding: 10px;
}

.honor-item h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.honor-item .issuer,
.honor-item .association,
.honor-item .description {
  margin: 3px 0; /* Reduce the margin between items */
  font-size: 0.9em;
  color: #666;
}

/* Prevent horizontal scrolling */
body {
  overflow-x: hidden;
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .sidebar {
    width: 100px; /* Adjust width for smaller screens */
    padding: 10px;
  }

  .content {
    margin-left: 110px; /* Adjust for the fixed sidebar width on smaller screens */
    width: calc(100% - 110px);
    padding: 10px;
  }

  .section {
    padding: 10px;
    margin-bottom: 20px;
  }

  .home-content {
    max-width: 100%; /* Remove max-width for mobile */
  }

  .home-content h1 {
    font-size: 2em; /* Adjust font size for mobile */
  }
}

.education-logo {
  width: 75px; /* Set a fixed width */
  height: auto; /* Let the height adjust automatically */
  margin-right: 10px;
  vertical-align: middle;
}

.levels {
  margin-bottom: 20px;
}

.levels h4 {
  font-size: 1.2em; /* Third largest font size for subheadings like Master of Physics, Thesis, Activities, etc. */
  margin: 0 0 10px 0;
  color: #333;
}

.levels-list {
  list-style-type: none;
  padding: 0;
  columns: 2;
  column-gap: 20px;
}

.levels-list li {
  margin: 5px 0;
  font-size: 1em; /* Regular font size for list items */
  color: #666;
}

.levels-list .grade {
  margin-left: 5px;
  font-weight: bold;
}

.education-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Reduce the bottom margin */
}

.education-header h3 {
  font-size: 1.5em; /* Second largest font size for institution name */
  margin: 0 0 5px 0; /* Reduce the bottom margin */
}

.education-header h4 {
  font-size: 1.2em;
}
.education-header .duration {
  margin: 0; /* Remove any margin */
}

.education-header h5 {
  font-size: 1em;
}
.education-header .title {
  margin: 0; /* Remove any margin */
}

.education-header h6 {
  font-size: 0.8em;
}
.education-item .grade {
  margin-top: 5px; /* Reduce the top margin */
}

.duration {
  font-style: italic;
}

.grade {
  font-weight: bold;
}

.activity-item h5 {
  font-size: 1.2em; /* Font size for activity name */
  margin: 0 0 5px 0;
  color: #555;
}

.activity-item p {
  font-size: 1em; /* Font size for activity details */
  margin: 0;
  color: #666;
}

.activity-logo {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
  vertical-align: middle;
}

.experience-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.company-logo {
  height: 50px; /* Set a fixed height */
  width: auto; /* Maintain aspect ratio */
  margin-right: 10px;
  vertical-align: middle;
}

.job-title {
  display: inline-block;
  margin: 0; /* Remove default margin */
}

.activity-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.activity-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
  vertical-align: middle;
}

a:visited {
  color: inherit; /* This will make the visited link color the same as the unvisited link color */
}

.badge {
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-top: 10px; /* Add some space above the badge */
}

.carousel-iframe {
  width: 100%;
  /* height: auto; */
  max-width: 560px;
  max-height: 315px;
}

.carousel-image-landscape {
  width: 100%;
  max-width: 560px;
  height: auto;
}

.carousel-image-portrait {
  width: 100%;
  max-width: 315px; 
  height: auto;
}
