body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Default styles for larger screens */
.sidebar {
  width: 250px;
  position: fixed;
  height: 100%;
  background-color: #f8f9fa;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container {
  text-align: center;
  padding: 20px 0;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar ul li {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.content {
  margin-left: 250px;
  padding: 20px;
}

/* Styles for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .content {
    margin-left: 0;
  }

  .sidebar ul {
    flex-direction: column;
    align-items: center;
  }

  .sidebar ul li {
    width: 100%;
    text-align: center;
  }
}

/* Additional styles for very small screens */
@media screen and (max-width: 480px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar ul {
    flex-direction: column;
    padding: 0;
  }

  .sidebar ul li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .profile-container {
    text-align: center;
    padding: 10px 0;
  }

  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}
